define("discourse/plugins/discourse-jira/initializers/add-discourse-jira-button", ["exports", "discourse/lib/plugin-api", "discourse/widgets/post-cooked", "discourse-common/lib/icon-library", "discourse/plugins/discourse-jira/discourse/components/modal/attach", "discourse/plugins/discourse-jira/discourse/components/modal/create"], function (_exports, _pluginApi, _postCooked, _iconLibrary, _attach, _create) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-jira",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      const modal = container.lookup("service:modal");
      if (siteSettings.discourse_jira_enabled) {
        (0, _pluginApi.withPluginApi)("0.8.8", api => {
          const currentUser = container.lookup("service:current-user");
          api.attachWidgetAction("post", "createIssue", function () {
            modal.show(_create.default, {
              model: this.model
            });
          });
          api.attachWidgetAction("post", "attachIssue", function () {
            modal.show(_attach.default, {
              model: this.model
            });
          });
          api.attachWidgetAction("post-menu", "toggleJiraMenu", function () {
            this.state.jiraVisible = !this.state.jiraVisible;
          });
          api.attachWidgetAction("post-menu", "closeJiraMenu", function () {
            this.state.jiraVisible = false;
          });
          api.includePostAttributes("jira_issue");
          api.addPostMenuButton("jira", attrs => {
            if (currentUser?.can_create_jira_issue && !attrs.jira_issue) {
              return {
                action: "toggleJiraMenu",
                icon: "fab-jira",
                className: "jira-menu",
                title: "discourse_jira.menu.title",
                position: "first"
              };
            }
          });
          api.decorateWidget("post-menu:before-extra-controls", helper => {
            if (!helper.state.jiraVisible) {
              return;
            }
            return helper.attach("post-jira-menu");
          });
          api.decorateWidget("post-contents:after-cooked", helper => {
            const postModel = helper.getModel();
            if (!postModel || !postModel.jira_issue) {
              return;
            }
            const jira = postModel.jira_issue;
            const jiraUrl = jira.self.replace(/\/rest\/api\/.*$/, "/browse/" + jira.key);
            const cooked = `
            <aside class='quote jira-issue' data-jira-key='${jira.key}'>
              <div class='title'>
                ${(0, _iconLibrary.iconHTML)("tag")}
                <a href='${jiraUrl}'>${jira.fields.summary}</a>
              </div>
              <blockquote>
                <i>(${jira.key})</i>
                <span class='jira-status jira-status-${jira.fields.status.id}'>
                  ${jira.fields.status.name}
                </span>
              </blockquote>
            </aside>
          `;
            const postCooked = new _postCooked.default({
              cooked
            }, helper);
            return helper.rawHtml(postCooked.init());
          });
        });
      }
    }
  };
});